import { FilterItem } from 'src/components/table-filter/@types';
import { TaxInsightsView } from '@newedge/reports';
import { PreloadedQuery } from 'react-relay';

interface TaxInsightsQueryReaderProps {
  queryRef?: PreloadedQuery<any>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
  data?: any;
}

export const TaxInsightsQueryReader = ({
  queryRef,
  filterValues,
  setFilterValues,
  setAsOfDate,
}: TaxInsightsQueryReaderProps) => {
  return (
    <TaxInsightsView
      // queryRef={cashFlowQueryRef}
      filterValues={filterValues}
      setFilterValues={setFilterValues}
      setAsOfDate={setAsOfDate}
    />
  );
};

export default TaxInsightsQueryReader;
