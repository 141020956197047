import { Grid, Box, Typography } from '@mui/material';
import { Suspense } from 'react';
import { TaxInsightTableRowData } from './@types';
import { computeToutData } from './taxInsightsHelper';
import {
  FilterConfig,
  FilterItem,
  TableFilter,
  Loading,
  ToutsWidget,
} from '@newedge/common';

interface TaxInsightViewProps {
  // queryRef: PreloadedQuery<CashFlowDetailViewQuery>;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
}

export const TaxInsightsView = ({
  filterValues,
  setFilterValues,
  setAsOfDate,
}: TaxInsightViewProps) => {
  // const date =
  //   data.clientsummary_viewer.getCashFlowDetailProjection?.[0]?.asOfDate;
  const date = '07/18/2023';

  if (date !== undefined && date !== null) {
    setAsOfDate(date);
  }

  const fakeToutData: TaxInsightTableRowData[] = [
    {
      category: 'Unrealized Gain/Loss',
      startMonth: 1,
      cashArray: [1, 2, 3],
      details: [],
      eyebrowLinkText: 'View holdings',
      eyebrowLinkPath: '/my/holdings',
    },
    {
      category: 'Realized Gain/Loss',
      startMonth: 1,
      cashArray: [1, 2, 3],
      details: [],
      eyebrowLinkText: 'View transactions',
      eyebrowLinkPath: '/my/account-activity',
      secondaryFilters: [
        {
          displayValue: 'Transaction Type: Sell Exchange',
          predicateIdentifier: 'transactionType',
          searchValue: 'Sell Exchange',
          filterType: 'text',
        } as FilterItem,
      ],
    },
    {
      category: 'Total Gain/Loss',
      startMonth: 1,
      cashArray: [1, 2, 3],
      details: [],
    },
  ];

  const fakeConfig: FilterConfig = {
    config: [
      {
        header: '',
        selections: [
          {
            predicateIdentifier: '',
            displayValue: '',
            searchValue: 'string ',
          },
        ],
        filterType: 'text',
      },
    ],
  };

  return (
    <Grid container spacing={3}>
      <Suspense fallback={<Loading />}>
        <ToutsWidget data={computeToutData(fakeToutData)} />
      </Suspense>
      <Grid item xs={8}>
        <TableFilter
          values={filterValues}
          setValues={setFilterValues}
          config={fakeConfig}
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant='h2'>Coming soon</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TaxInsightsView;
