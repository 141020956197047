import { MobileReportTable } from '@newedge/common';
import PerformanceTable from '../performance-table/PerformanceTable';
import { OrionApiAccountGroupStatus, PerformanceTableRowData } from '../@types';
import { nestedTableHeaderRenderer } from '../performanceHelpers';
import { mobileReportColumns } from '.';
import { Skeleton } from '@mui/material';
import isDataReady from './utils/isDataReady';

interface AcctGroupsPanelContentProps {
  status?: OrionApiAccountGroupStatus[];
  accountGroupPerformanceData: PerformanceTableRowData[];
  setHeadCellWidths: React.Dispatch<React.SetStateAction<{}>>;
}

export const AcctGroupsPanelContent = ({
  status,
  accountGroupPerformanceData,
  setHeadCellWidths,
}: AcctGroupsPanelContentProps) => {
  if (!status || isDataReady(status)) {
    return (
      <PerformanceTable
        data={accountGroupPerformanceData}
        setHeadCellWidths={setHeadCellWidths}
        byHousehold={false}
      />
    );
  }
  return <Skeleton variant='rectangular' width='100%' height={200} />;
};

interface AcctGroupsMobileTableProps {
  status?: OrionApiAccountGroupStatus[];
  accountGroupPerformanceData: PerformanceTableRowData[];
}

export const AcctGroupsMobileTable = ({
  status,
  accountGroupPerformanceData,
}: AcctGroupsMobileTableProps) => {
  if (!status || isDataReady(status)) {
    return (
      <MobileReportTable
        columns={mobileReportColumns}
        data={accountGroupPerformanceData}
        headerId='name'
        nestedHeaderId='name'
        nestedAccessor={(rowData) => rowData.nestedData!}
        customNestedTableHeaderRenderer={nestedTableHeaderRenderer}
        hasParentHeaders={false}
        hasCustomNestedTable={true}
        positiveNumberDecorator={true}
      />
    );
  }
  return <Skeleton height={250} width='100%' />;
};
