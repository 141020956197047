import { MobileReportTable, TooltipWrapper } from '@newedge/common';
import { HouseholdTableRowData, OrionApiPerformanceStatus } from '../@types';
import PerformanceTable from '../performance-table/PerformanceTable';
import { mobileReportColumns } from '.';
import { Box, Skeleton } from '@mui/material';
import isDataReady from './utils/isDataReady';

interface HoldingsPanelContentProps {
  status?: OrionApiPerformanceStatus[];
  holdingsPerformanceData: HouseholdTableRowData[];
  setHeadCellWidths: React.Dispatch<React.SetStateAction<{}>>;
}

export const HoldingsPanelContent = ({
  status,
  holdingsPerformanceData,
  setHeadCellWidths,
}: HoldingsPanelContentProps) => {
  if (!status || isDataReady(status)) {
    return (
      <PerformanceTable
        data={holdingsPerformanceData}
        setHeadCellWidths={setHeadCellWidths}
      />
    );
  }
  return <Skeleton variant='rectangular' width='100%' height={200} />;
};

interface HoldingsMobileTableProps {
  status?: OrionApiPerformanceStatus[];
  holdingsPerformanceData: HouseholdTableRowData[];
}

export const HoldingsMobileTable = ({
  status,
  holdingsPerformanceData,
}: HoldingsMobileTableProps) => {
  if (!status || isDataReady(status)) {
    return (
      <MobileReportTable
        columns={mobileReportColumns}
        data={holdingsPerformanceData}
        headerId='name'
        nestedHeaderId='name'
        nestedAccessor={(rowData) => rowData.nestedData!}
        customTileListRenderer={(nestedData) => {
          return (
            <MobileReportTable
              columns={mobileReportColumns}
              data={nestedData}
              headerId='name'
              nestedHeaderId='name'
              nestedAccessor={(rowData) => rowData.nestedData!}
              customNestedHeaderRenderer={(row, nestedRow) => {
                return (
                  <Box
                    sx={(theme) => ({
                      fontSize: '1.4rem',
                      borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
                      paddingBottom: theme.spacing(0.5),
                      marginBottom: theme.spacing(1),
                      display: 'flex',
                      justifyContent: 'space-between',
                    })}
                  >
                    <TooltipWrapper
                      text={nestedRow.name}
                      maxLength={72}
                      typographyVariant='link'
                    />
                  </Box>
                );
              }}
            />
          );
        }}
        hasParentHeaders={false}
        positiveNumberDecorator={true}
      />
    );
  }
  return <Skeleton height={250} width='100%' />;
};
