import { Box } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { useMemo, Suspense, useState, useCallback, useContext } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';
import { RouteObject, Navigate, useRoutes } from 'react-router';
import { NavigationRouteDefinition } from 'src/@types/global';
import NavigationDrawer from 'src/components/navigation/NavigationDrawer';
import { TopBar } from 'src/components/root';
import PageWrapperOutlet from 'src/components/root/PageWrapperOutlet';
import { PropsWithRelayRouteQueryRef } from 'src/libs/routing/@types';
import TermsAndConditionsRouteGuard from '../components/terms-and-conditions/TermsAndConditionsRouteGuard';
import { ProfileView } from './profile';
import { LandingPageViewQuery } from './__generated__/LandingPageViewQuery.graphql';
import { LandingPageView_ViewerAcceptsTermsVersionMutation } from './__generated__/LandingPageView_ViewerAcceptsTermsVersionMutation.graphql';
//icons
import HomeIcon from '@mui/icons-material/Home';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GradingIcon from '@mui/icons-material/Grading';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonIcon from '@mui/icons-material/Person';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { PublishedWithChanges } from '@mui/icons-material';
import { AssetAllocationIcon, HoldingsDetailIcon } from 'src/components/icons';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { DashboardLoader } from './dashboard/DashboardView';
import { CashFlowDetailLoader } from './cash-flow/CashFlowDetailLoader';
import { HoldingsDetailLoader } from './holdings/HoldingsDetailLoader';
import { AssetAllocationDetailLoader } from './asset-allocation/AssetAllocationDetailLoader';
import { PerformanceLoader } from './performance/PerformanceLoader';
import { Loading } from 'src/components/loading';
import getIdFromNodeId from 'src/libs/utils/getIdFromNodeId';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import { TaxInsightsLoader } from './tax-insights/TaxInsightsLoader';
import { PortfolioChangeLoader } from './portfolio-change/PortfolioChangeView';
import { AccountBalancesLoader } from './account-balances/AccountBalancesLoader';
import { AccountActivityLoader } from './account-activity/AccountActivityLoader';

export const CURRENT_TERMS_VERSION = '2.0';

// TODO: Break this up. We should only load necessary data for the app to load in the root query
// Then lazy load the individuals view data afterward
export const LandingPageViewGqlQuery = graphql`
  query LandingPageViewQuery {
    userprofile_viewer {
      id
      userId
      firstName
      lastName
      email
      displayName
      acceptedTermsVersion
      ...ProfileView_userprofile_viewer
    }
    clientsummary_viewer {
      financialAccounts {
        accountName
        clientIdentifier
        id
      }
      ...ProfileView_clientsummary_viewer
    }
    orionapiservice_viewer {
      id
      getAccountGroupPerformance {
        accountGroupId
        status
        startTime
        endTime
        accountIds
      }
      getUngroupedAccountPerformance {
        accountGroupId
        status
        startTime
        endTime
        accountIds
      }
      getHoldingsPerformance {
        householdId
        status
        startTime
        endTime
      }
    }
    ...GlobalAccountSelectWrapper_query
  }
`;

export const ViewerAcceptsTermsVersionMutation = graphql`
  mutation LandingPageView_ViewerAcceptsTermsVersionMutation(
    $input: AcceptTermsInput!
  ) {
    AcceptTerms(input: $input) {
      acceptedTermsVersion
    }
  }
`;

type LandingPageViewProps = PropsWithRelayRouteQueryRef<LandingPageViewQuery>;

const LandingPageView = ({
  routeQueryRef,
  loadRouteQuery,
}: LandingPageViewProps) => {
  const data = usePreloadedQuery(LandingPageViewGqlQuery, routeQueryRef);

  const { setSelectedAccounts, setAllAccounts, allAccounts } =
    useContext(GlobalFilterContext);

  const allAccountIds = useMemo(
    () =>
      data?.clientsummary_viewer?.financialAccounts
        .map((o) => {
          return getIdFromNodeId(o.id);
        })
        .sort(),
    [data?.clientsummary_viewer?.financialAccounts]
  );

  if (allAccountIds && allAccounts.length === 0) {
    setAllAccounts(allAccountIds);
    setSelectedAccounts(allAccountIds);
  }

  const [commitViewerAcceptedTermsVersion] =
    useMutation<LandingPageView_ViewerAcceptsTermsVersionMutation>(
      ViewerAcceptsTermsVersionMutation
    );

  const [drawerOpen, setDrawerOpen] = useState(false);

  const navRoutes: NavigationRouteDefinition[] = useMemo(
    () => [
      {
        title: 'Dashboard',
        path: 'dashboard',
        icon: <HomeIcon fontSize="inherit" />,
        element: <DashboardLoader landingPageQueryRef={routeQueryRef} />,
      },
      {
        title: 'Reports',
        groupTitle: true,
        icon: <LegendToggleIcon fontSize="inherit" />,
      },
      // {
      //   title: 'Recent Activity',
      //   path: 'activity',
      //   element: (
      //     <RecentActivityView fragmentQueryRef={data.userprofile_viewer} />
      //   ),
      //   nested: 'Reports',
      //   icon: <AccessTimeIcon />,
      // },
      {
        title: 'Expected Cash Flow',
        path: 'cash-flow',
        element: <CashFlowDetailLoader landingPageQueryRef={routeQueryRef} />,
        nested: 'Reports',
        icon: <LocalAtmIcon fontSize="inherit" />,
      },
      {
        title: 'Asset Allocation',
        path: 'asset-allocation',
        element: (
          <AssetAllocationDetailLoader landingPageQueryRef={routeQueryRef} />
        ),
        nested: 'Reports',
        icon: <AssetAllocationIcon fontSize="inherit" />,
      },
      {
        title: 'Holdings',
        path: 'holdings',
        element: <HoldingsDetailLoader landingPageQueryRef={routeQueryRef} />,
        nested: 'Reports',
        icon: <HoldingsDetailIcon fontSize="inherit" />,
      },
      {
        title: 'Account Activity',
        path: 'account-activity',
        element: <AccountActivityLoader landingPageQueryRef={routeQueryRef} />,
        nested: 'Reports',
        icon: <AccessTimeIcon fontSize="inherit" />,
      },
      {
        title: 'Account Balances',
        path: 'account-balances',
        element: <AccountBalancesLoader landingPageQueryRef={routeQueryRef} />,
        nested: 'Reports',
        icon: <AccountBalanceWalletIcon fontSize="inherit" />,
      },
      ...(process.env.REACT_APP_HIDE_REPORT_PORTFOLIO_CHANGE !== 'true'
        ? [
            {
              title: 'Portfolio Change',
              path: 'portfolio-change',
              element: <PortfolioChangeLoader data={[]} />,
              nested: 'Reports',
              icon: <PublishedWithChanges fontSize="inherit" />,
            },
          ]
        : []),
      ...(process.env.REACT_APP_HIDE_REPORT_PERFORMANCE !== 'true'
        ? [
            {
              title: 'Performance',
              path: 'performance',
              element: <PerformanceLoader />,
              nested: 'Reports',
              icon: <GradingIcon fontSize="inherit" />,
            },
          ]
        : []),
      ...(process.env.REACT_APP_HIDE_REPORT_TAX_INSIGHTS !== 'true'
        ? [
            {
              title: 'Tax Insights',
              path: 'tax-insights',
              element: <TaxInsightsLoader data={[]} />,
              nested: 'Reports',
              icon: <PlagiarismIcon fontSize="inherit" />,
            },
          ]
        : []),
      // {
      //   title: 'Market Activity',
      //   path: 'market',
      //   element: (
      //     <MarketInsightsView fragmentQueryRef={data.userprofile_viewer} />
      //   ),
      //   icon: <EqualizerIcon />,
      // },
      // {
      //   title: 'Communications',
      //   path: 'communications',
      //   element: (
      //     <CommunicationsView
      //       fragmentQueryRef={data.correspondence_viewer}
      //       displayName={data.userprofile_viewer?.displayName!}
      //     />
      //   ),
      //   icon: <ForumIcon />,
      // },
      // {
      //   title: 'Documents',
      //   path: 'documents',
      //   element: <DocumentsView fragmentQueryRef={data.userprofile_viewer} />,
      //   icon: <AssignmentIcon />,
      // },
      {
        title: 'Profile',
        path: 'profile',
        element: (
          <ProfileView
            userProfileFragmentQueryRef={data.userprofile_viewer}
            clientSummaryFragmentQueryRef={data.clientsummary_viewer}
          />
        ),
        icon: <PersonIcon fontSize="inherit" />,
      },
    ],
    [routeQueryRef, data.clientsummary_viewer, data.userprofile_viewer]
  );
  const routes = useRoutes([
    {
      element: <PageWrapperOutlet />,
      children: navRoutes,
    },
    {
      path: '*',
      element: <Navigate to="/error/404" replace />,
    },
  ] as RouteObject[]);

  const handleTermsAccepted = useCallback(() => {
    // GQL Mutation to state user accepted which version.
    commitViewerAcceptedTermsVersion({
      variables: {
        input: {
          termsVersion: CURRENT_TERMS_VERSION,
        },
      },
    });
  }, [commitViewerAcceptedTermsVersion]);

  return (
    <Suspense fallback={<Loading />}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          boxSizing: 'border-box',
        }}
      >
        <NavigationDrawer
          isDrawerOpen={drawerOpen}
          toggleDrawerOpen={setDrawerOpen}
          navDefinitions={navRoutes}
          onNavigation={(location: string): void => {
            if (location === '/my/profile') {
              loadRouteQuery({}, { fetchPolicy: 'network-only' });
            }
          }}
        />
        <Box sx={{ width: '100%' }}>
          <TopBar
            userDisplayName={data.userprofile_viewer?.displayName}
            toggleDrawerOpen={setDrawerOpen}
          />
          <TermsAndConditionsRouteGuard
            acceptedTermsVersion={
              data.userprofile_viewer?.acceptedTermsVersion || ''
            }
            currentTermsVersion={CURRENT_TERMS_VERSION}
            onTermsAccepted={handleTermsAccepted}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: {
                  xs: '95px',
                  md: 'unset',
                },
              }}
            >
              {routes}
            </Box>
          </TermsAndConditionsRouteGuard>
        </Box>
      </Box>
    </Suspense>
  );
};

export default LandingPageView;
