import {
  UserProfileAccountGroup,
  UserProfileFinancialAccountNickname,
} from '@newedge/common';
import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { PerformanceQueryReaderQuery } from './__generated__/PerformanceQueryReaderQuery.graphql';
import { PerformanceQueryReaderBenchmarkQuery } from './__generated__/PerformanceQueryReaderBenchmarkQuery.graphql';
import combineUserProfileAndClientSummaryFinancialAccountInfo from 'src/models/ClientPortalFinancialAccount';
import { FinancialAccountInfo } from 'src/@types/global';
import { useContext } from 'react';
import { GlobalFilterContext } from 'src/components/context/GlobalFilterContext';
import {
  PerformanceByAssetClassModel,
  PerformanceBySubAssetClassModel,
  PerformanceByHoldingsModel,
  PerformanceNestedTableRowData,
  PerformanceView,
  projectPerformanceByAssetClassData,
  projectPerformanceBySubAssetClassData,
  projectPerformanceByHoldingsData,
  PerformanceByManagementStyleModel,
  projectPerformanceByManagementStyleData,
  projectPerformanceByAccountGroupData,
  PerformanceByAccountGroupModel,
  HouseholdPerformanceModel,
  PerformanceByProductModel,
  PerformanceByAccountModel,
  OrionApiAccountGroupStatus,
  OrionApiPerformanceStatusData,
  OrionApiHoldingsStatus,
} from '@newedge/reports';

export const PerformanceBenchmarkViewQueryNode = graphql`
  query PerformanceQueryReaderBenchmarkQuery {
    clientsummary_viewer {
      id
      getPortfolioPerformanceBenchmark {
        asOfDate
        fiveYearReturns
        monthToDate
        oneYearReturns
        portfolioPerformanceBenchmarkId
        quarterToDate
        tenYearReturns
        threeYearReturns
        yearToDate
        benchmarkName
      }
    }
  }
`;

export const PerformanceViewQueryNode = graphql`
  query PerformanceQueryReaderQuery(
    $performanceInput: GetPortfolioPerformanceInput!
    $performanceModelFilterInput: PortfolioPerformanceModelFilterInput
  ) {
    orionapiservice_viewer {
      id
      getAccountGroupPerformance {
        accountGroupId
        status
        startTime
        endTime
        accountIds
      }
      getUngroupedAccountPerformance {
        accountGroupId
        status
        startTime
        endTime
        accountIds
      }
      getHoldingsPerformance {
        householdId
        status
        startTime
        endTime
      }
    }
    userprofile_viewer {
      id
      accounts {
        accountNickname
        financialAccountId
      }
      accountGroups {
        id
        name
        accounts {
          id
          financialAccountId
        }
      }
    }
    clientsummary_viewer {
      id
      getPerformanceByHousehold {
        performanceByHouseholdId
        asOfDate
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
        householdName
        householdId
      }
      getPerformanceByAccount {
        performanceByAccountId
        accountId
        accountName
        managementStyleId
        managementStyle
        custodian
        asOfDate
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
        householdId
        householdName
      }
      getPortfolioPerformance(
        input: $performanceInput
        where: $performanceModelFilterInput
      ) {
        accountDefaultName
        accountId
        asOfDate
        custodian
        fiveYearReturns
        inceptionDate
        managementStyle
        marketValue
        monthToDate
        netGross
        oneYearReturns
        portfolioPerformanceId
        quarterToDate
        sinceInception
        tenYearReturns
        threeYearReturns
        yearToDate
      }
      getPerformanceByAccountGroup {
        performanceByAccountGroupId
        accountGroupId
        asOfDate
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
      }
      getPerformanceByAssetClassForAssetValue {
        performanceByAssetClassForAssetValueId
        asOfDate
        assetClassId
        assetClassName
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
        householdName
        householdId
      }
      getPerformanceBySubAssetClassForSubAssetValue {
        performanceBySubAssetClassForSubAssetValueId
        asOfDate
        subAssetClassId
        subAssetClassName
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
        householdName
        householdId
      }
      getPerformanceByHoldingsForAccountValue {
        performanceByHoldingsForAccountValueId
        asOfDate
        accountId
        accountName
        custodian
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
        householdName
        productName
        productId
        householdId
      }
      getPerformanceByManagementStyle {
        performanceByManagementStyleId
        asOfDate
        managementStyleId
        managementStyle
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        inceptionDate
        householdName
        householdId
      }
      getPerformanceByProduct {
        performanceByProductId
        householdName
        productId
        productName
        assetClassId
        assetClassName
        subAssetClassId
        subAssetClassName
        asOfDate
        inceptionDate
        marketValue
        monthToDate
        quarterToDate
        yearToDate
        oneYearReturns
        threeYearReturns
        fiveYearReturns
        tenYearReturns
        sinceInception
        householdId
      }
      financialAccounts {
        id
        accountName
        clientIdentifier
        accountNumber
        registrationName
        managementStyle
        accountType
        accountTypeAbbreviation
        custodian
      }
    }
  }
`;

interface PerformanceQueryReaderProps {
  performanceQueryRef: PreloadedQuery<PerformanceQueryReaderQuery>;
  benchmarkQueryRef: PreloadedQuery<PerformanceQueryReaderBenchmarkQuery>;
}

export const PerformanceQueryReader = ({
  performanceQueryRef,
  benchmarkQueryRef,
}: PerformanceQueryReaderProps) => {
  const data = usePreloadedQuery(PerformanceViewQueryNode, performanceQueryRef);

  const benchmarkData = usePreloadedQuery(
    PerformanceBenchmarkViewQueryNode,
    benchmarkQueryRef
  );

  const mappedBenchmarkData: PerformanceNestedTableRowData[] =
    benchmarkData.clientsummary_viewer.getPortfolioPerformanceBenchmark.map(
      (benchmarkItem) => {
        return {
          name: benchmarkItem.benchmarkName,
          monthToDate: benchmarkItem.monthToDate,
          quarterToDate: benchmarkItem.quarterToDate,
          yearToDate: benchmarkItem.yearToDate,
          oneYearReturns: benchmarkItem.oneYearReturns,
          threeYearReturns: benchmarkItem.threeYearReturns,
          fiveYearReturns: benchmarkItem.fiveYearReturns,
          tenYearReturns: benchmarkItem.tenYearReturns,
        } as PerformanceNestedTableRowData;
      }
    );

  const benchmarkAsOfDate =
    benchmarkData.clientsummary_viewer?.getPortfolioPerformanceBenchmark[0]
      .asOfDate;

  const accounts = combineUserProfileAndClientSummaryFinancialAccountInfo(
    data.userprofile_viewer?.accounts as UserProfileFinancialAccountNickname[],
    data.clientsummary_viewer?.financialAccounts as FinancialAccountInfo[]
  );

  const performanceDataWithNicknames:
    | PerformanceNestedTableRowData[]
    | undefined = data.clientsummary_viewer?.getPerformanceByAccount?.map(
    (o) => {
      const nickname = accounts.find(
        (account) => account.nicknameEntry?.financialAccountId === o.accountId
      )?.nicknameEntry?.accountNickname;
      return {
        ...o,
        name: nickname ? nickname : o.accountName,
      } as PerformanceNestedTableRowData;
    }
  );

  const accountGroups = data.userprofile_viewer
    ?.accountGroups as UserProfileAccountGroup[];

  const { selectedAccounts } = useContext(GlobalFilterContext);

  const performanceByHouseholdData =
    data.clientsummary_viewer.getPerformanceByHousehold;

  const performanceByAccountGroupData = projectPerformanceByAccountGroupData(
    accounts,
    accountGroups,
    data.clientsummary_viewer
      ?.getPerformanceByAccount as unknown as PerformanceByAccountModel[],
    data.clientsummary_viewer
      ?.getPerformanceByAccountGroup as unknown as PerformanceByAccountGroupModel[]
  );

  const performanceByAssetClassData = projectPerformanceByAssetClassData(
    data.clientsummary_viewer
      .getPerformanceByAssetClassForAssetValue as unknown as PerformanceByAssetClassModel[],
    data.clientsummary_viewer
      .getPerformanceByProduct as unknown as PerformanceByProductModel[],
    performanceByHouseholdData as unknown as HouseholdPerformanceModel[]
  );

  const performanceBySubAssetClassData = projectPerformanceBySubAssetClassData(
    data.clientsummary_viewer
      .getPerformanceBySubAssetClassForSubAssetValue as unknown as PerformanceBySubAssetClassModel[],
    data.clientsummary_viewer
      .getPerformanceByProduct as unknown as PerformanceByProductModel[],
    performanceByHouseholdData as unknown as HouseholdPerformanceModel[]
  );

  const performanceByHoldingsData = projectPerformanceByHoldingsData(
    data.clientsummary_viewer?.getPerformanceByHoldingsForAccountValue?.map(
      (o) => {
        const nickname = accounts.find(
          (account) => account.nicknameEntry?.financialAccountId === o.accountId
        )?.nicknameEntry?.accountNickname;
        return {
          ...o,
          name: nickname ? nickname : o.accountName,
        };
      }
    ) as unknown as PerformanceByHoldingsModel[],
    data.clientsummary_viewer
      .getPerformanceByProduct as unknown as PerformanceByProductModel[],
    performanceByHouseholdData as unknown as HouseholdPerformanceModel[]
  );

  const performanceByManagementStyleData =
    projectPerformanceByManagementStyleData(
      performanceDataWithNicknames as PerformanceByManagementStyleModel[],
      data.clientsummary_viewer
        .getPerformanceByManagementStyle as unknown as PerformanceByManagementStyleModel[],
      performanceByHouseholdData as unknown as HouseholdPerformanceModel[]
    );

  const orionApiServiceData: OrionApiPerformanceStatusData = {
    accountGroups: data.orionapiservice_viewer
      ?.getAccountGroupPerformance as unknown as OrionApiAccountGroupStatus[],
    holdings: data.orionapiservice_viewer
      ?.getHoldingsPerformance as unknown as OrionApiHoldingsStatus[],
  };

  return (
    <PerformanceView
      orionApiServiceData={orionApiServiceData}
      benchmarkData={mappedBenchmarkData}
      benchmarkAsOfDate={benchmarkAsOfDate}
      accounts={accounts}
      selectedAccounts={selectedAccounts}
      accountGroupPerformanceData={performanceByAccountGroupData}
      performanceByAssetClassData={performanceByAssetClassData}
      performanceBySubAssetClassData={performanceBySubAssetClassData}
      performanceByHoldingsData={performanceByHoldingsData}
      performanceByManagementStyleData={performanceByManagementStyleData}
    />
  );
};
