import Skeleton from '@mui/material/Skeleton';
import { Suspense, useState } from 'react';
import ReportWrapper from '../ReportWrapper';
import { FilterItem } from 'src/components/table-filter/@types';
import TaxInsightsQueryReader from './TaxInsightsQueryReader';

interface TaxInsightsLoaderProps {
  // landingPageQueryRef: PreloadedQuery<LandingPageViewQuery>;
  data: any;
}
export const TaxInsightsLoader = ({ data }: TaxInsightsLoaderProps) => {
  // const { TaxInsightsQueryVariables } = useContext(QueryVariablesContext);
  // const [cashFlowQueryRef, loadCashFlowQuery] =
  //   useQueryLoader<TaxInsightsViewQuery>(TaxInsightsViewQueryNode);

  const fakeTableFilterData: FilterItem[] = [
    {
      predicateIdentifier: '',
      displayValue: '',
      filterType: 'text',
    },
  ];
  const [filterValues, setFilterValues] =
    useState<FilterItem[]>(fakeTableFilterData);

  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  // useEffect(() => {
  //   loadCashFlowQuery(
  //     applySecondaryFilters(TaxInsightsQueryVariables, filterValues)
  //   );
  // }, [TaxInsightsQueryVariables, filterValues, loadCashFlowQuery]);

  return (
    <ReportWrapper title="Tax Insights" landingPageQueryRef={data}>
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <TaxInsightsQueryReader
          //queryRef={accountBalancesQueryRef}
          filterValues={filterValues}
          setAsOfDate={setAsOfDate}
          setFilterValues={setFilterValues}
        />
      </Suspense>
    </ReportWrapper>
  );
};

export default TaxInsightsLoader;
