import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { appConfig } from '..';

let appInsights: ApplicationInsights;
let reactPlugin: ReactPlugin;

if (appConfig.appInsightsConnectionString) {
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      connectionString: appConfig.appInsightsConnectionString,
      extensions: [reactPlugin],
      // enableDebug: true,
      // loggingLevelConsole: 2,
      // loggingLevelTelemetry: 2,
      // enableAjaxErrorStatusText: true,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
    },
  });
}

export { appInsights, reactPlugin };

const routeToPageMap: { [key: string]: string } = {
  '/my/dashboard': 'Dashboard',
  '/my/cash-flow': 'Expected Cash Flow',
  '/my/asset-allocation': 'Asset Allocation',
  '/my/holdings': 'Holdings',
  '/my/account-activity': 'Account Activity',
  '/my/account-balances': 'Account Balances',
  '/my/portfolio-change': 'Portfolio Change',
  '/my/performance': 'Performance',
  '/my/tax-insights': 'Tax Insights',
  '/my/profile': 'Profile',
  '/logged-out': 'Logged Out',
};

export const initAppInsights = () => {
  appInsights?.addTelemetryInitializer((telemetryItem) => {
    try {
      // console.log(telemetryItem);
      if (
        telemetryItem.baseType === 'PageviewData' ||
        telemetryItem.baseType === 'PageviewPerformanceData'
      ) {
        const route = telemetryItem.ext?.trace.name as string;
        if (
          (route in routeToPageMap || route.startsWith('/error/')) &&
          telemetryItem.baseData
        ) {
          let pageName;
          if (route.startsWith('/error/')) {
            pageName = 'Oops';
          } else {
            pageName = routeToPageMap[route];
          }
          telemetryItem.baseData.name = pageName;
        }
      }

      if (
        telemetryItem.baseType === 'MetricData' &&
        telemetryItem.baseData?.properties?.PageName
      ) {
        const pageUrl = telemetryItem.baseData.properties.PageUrl as string;
        Object.keys(routeToPageMap).forEach((key) => {
          if (pageUrl && pageUrl.endsWith(key) && telemetryItem.baseData) {
            telemetryItem.baseData.properties.PageName = routeToPageMap[key];
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  });

  // appInsights.addDependencyInitializer((item) => {
  //   console.log(item);
  // });

  // appInsights.addDependencyListener((details) => {
  //   console.log(details);
  // });

  appInsights?.loadAppInsights();
};
