import { useQueryLoader } from 'react-relay';
import {
  PerformanceBenchmarkViewQueryNode,
  PerformanceQueryReader,
  PerformanceViewQueryNode,
} from './PerformanceQueryReader';
import { PerformanceQueryReaderQuery } from './__generated__/PerformanceQueryReaderQuery.graphql';
import { PerformanceQueryReaderBenchmarkQuery } from './__generated__/PerformanceQueryReaderBenchmarkQuery.graphql';
import { Suspense, useContext, useEffect } from 'react';
import { QueryVariablesContext } from 'src/components/context/QueryVariablesContext';
import ReportWrapper from '../ReportWrapper';
import { Skeleton } from '@mui/material';

export const PerformanceLoader = () => {
  const [performanceQueryRef, loadPerformanceQuery] =
    useQueryLoader<PerformanceQueryReaderQuery>(PerformanceViewQueryNode);

  const [benchmarkQueryRef, loadBenchmarkQuery] =
    useQueryLoader<PerformanceQueryReaderBenchmarkQuery>(
      PerformanceBenchmarkViewQueryNode
    );

  const { performanceQueryVariables, performanceBenchmarkQueryVariables } =
    useContext(QueryVariablesContext);

  useEffect(() => {
    loadPerformanceQuery(performanceQueryVariables);
  }, [performanceQueryVariables, loadPerformanceQuery]);

  useEffect(() => {
    loadBenchmarkQuery(performanceBenchmarkQueryVariables);
  }, [loadBenchmarkQuery, performanceBenchmarkQueryVariables]);

  return performanceQueryRef != null && benchmarkQueryRef != null ? (
    <ReportWrapper title="Performance" hideGlobalAccountSelect>
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <PerformanceQueryReader
          performanceQueryRef={performanceQueryRef}
          benchmarkQueryRef={benchmarkQueryRef}
        />
      </Suspense>
    </ReportWrapper>
  ) : null;
};

export default PerformanceLoader;
