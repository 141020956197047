import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons, setIconOptions } from '@fluentui/react';
import { lazy, useEffect } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { RouteObject, useRoutes } from 'react-router';
import { GlobalFilterProvider } from './components/context/GlobalFilterContext';
import { QueryVariablesProvider } from './components/context/QueryVariablesContext';
import { StyledEngineProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { NewEdgeThemeProvider } from '@newedge/theme';
import { initAuth } from '@newedge/auth';
import { allowedClaims, appConfig } from './libs';
import { RelayRouteWithQuery, createRelayEnvironment } from '@newedge/relay';
import { wrapAuthRoutes } from '@newedge/routing';
import { Loading } from '@newedge/common';
import { LandingPageViewGqlQuery } from './views/LandingPageView';
import { initAppInsights } from './libs/config/AppInsights';

const LandingPageView = lazy(() => import('./views/LandingPageView'));

const { msalClient, accessScopes, getAccessToken } = initAuth(appConfig);

const gatewayRelayEnv = createRelayEnvironment({
  getAccessToken,
  endpoint: appConfig.apiDomain,
  scopes: [accessScopes.gatewayApi],
});
if (!gatewayRelayEnv) throw new Error('Could not initialize GraphQL client!');

const authRoutes: RouteObject[] = [
  {
    path: '*',
    element: (
      <RelayRouteWithQuery
        innerComponent={LandingPageView}
        query={LandingPageViewGqlQuery}
        initialVariables={{}}
        fallback={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Loading />
          </Box>
        }
      />
    ),
  },
];

const wrappedRoutes = wrapAuthRoutes(authRoutes, allowedClaims);

initAppInsights();

const App = () => {
  initializeIcons();
  setIconOptions({
    disableWarnings: true,
  });

  const cancelPasswordResetErrorCode = 'AADB2C90091';
  const prefersDarkMode = true; // useMediaQuery('(prefers-color-scheme: dark)');

  const isCancelPasswordResetEvent = (
    event: EventMessage
  ): boolean | undefined => {
    return (
      event.eventType === EventType.LOGIN_FAILURE &&
      event.error?.message?.includes(cancelPasswordResetErrorCode)
    );
  };

  useEffect(() => {
    msalClient.enableAccountStorageEvents();
    // Handles setting the primary account during a login and logging out across tabs
    const callbackId = msalClient.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        msalClient.setActiveAccount(account);
      } else if (event.eventType === EventType.ACCOUNT_ADDED) {
        const newAccount = event.payload as AccountInfo;
        msalClient.setActiveAccount(newAccount);
      } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
        const removedAccount = event.payload as AccountInfo;
        msalClient.logoutRedirect({
          account: removedAccount,
        });
      } else if (isCancelPasswordResetEvent(event)) {
        msalClient.loginRedirect();
      }
    });

    return () => {
      msalClient.disableAccountStorageEvents();
      if (!callbackId) return;
      msalClient.removeEventCallback(callbackId);
    };
  }, []);

  const routes = useRoutes(wrappedRoutes);

  return (
    <>
      <MsalProvider instance={msalClient}>
        <RelayEnvironmentProvider environment={gatewayRelayEnv}>
          <StyledEngineProvider injectFirst>
            <NewEdgeThemeProvider prefersDarkMode={prefersDarkMode}>
              <GlobalFilterProvider>
                <QueryVariablesProvider>{routes}</QueryVariablesProvider>
              </GlobalFilterProvider>
              <CssBaseline />
            </NewEdgeThemeProvider>
          </StyledEngineProvider>
        </RelayEnvironmentProvider>
      </MsalProvider>
    </>
  );
};

export default App;
