import _ from 'lodash';
import { newEdgeNamedColors } from '@newedge/theme';
import {
  UserProfileAccountGroup,
  TileProps,
  abbreviateNumber,
} from '@newedge/common';
import {
  AccountBalancesModel,
  AccountBalanceViewModel,
  AccountGroupBalanceViewModel,
} from './@types';

export const computeAccountBalancesToutData = (
  accountBalances: AccountBalancesModel[] | undefined
): TileProps[] => {
  let toutData: TileProps[] = [];
  if (!accountBalances) {
    return toutData;
  }

  const nonLiabilityAccounts = accountBalances.filter(
    (x) => x?.isLiability === false
  );

  const liabilityAccounts = accountBalances.filter(
    (x) => x?.isLiability === true
  );

  const initialValue = 0;

  const totalAssets = nonLiabilityAccounts.reduce(
    (previousValue, currentValue) => previousValue + currentValue.totalBalance,
    initialValue
  );

  const totalLiabilities = liabilityAccounts.reduce(
    (previousValue, currentValue) => previousValue + currentValue.totalBalance,
    initialValue
  );

  const netAssets = accountBalances.reduce(
    (previousValue, currentValue) => previousValue + currentValue.totalBalance,
    initialValue
  );

  toutData = [
    {
      eyebrowText: 'Total Assets',
      headlineText: abbreviateNumber(totalAssets),
      color: newEdgeNamedColors.white,
      smallHeadline: false,
    },
    {
      eyebrowText: 'Total Liabilities',
      headlineText:
        liabilityAccounts && liabilityAccounts.length
          ? abbreviateNumber(totalLiabilities)
          : 'N/A',
      color: newEdgeNamedColors.white,
      smallHeadline: false,
    },
    {
      eyebrowText: 'Net Assets',
      headlineText: abbreviateNumber(netAssets),
      color: newEdgeNamedColors.white,
      smallHeadline: false,
    },
  ];

  return toutData;
};

const calculateDailyChange = (accounts: AccountBalancesModel[]): number => {
  let totalBeginningMarketValue = 0;
  let totalEndingMarketValue = 0;
  let totalNetCashFlows = 0;
  accounts.forEach((acct) => {
    totalBeginningMarketValue += acct.beginningMarketValue;
    totalEndingMarketValue += acct.endingMarketValue;
    totalNetCashFlows += acct.netCashFlows;
  });
  return totalBeginningMarketValue === 0
    ? 0
    : (totalEndingMarketValue - totalNetCashFlows - totalBeginningMarketValue) /
        totalBeginningMarketValue;
};

const projectAccountGroupBalance = (
  accountGroup: UserProfileAccountGroup,
  accountBalancesForAccountGroup: AccountBalancesModel[]
): AccountGroupBalanceViewModel => {
  let accountGroupBalance: AccountGroupBalanceViewModel = {
    AccountGroupId: accountGroup.id ?? '',
    AccountGroupName: accountGroup.name,
    MarketValue: 0,
    CashBalance: 0,
    TotalBalance: 0,
    UnrealizedGl: 0,
    UnrealizedGlPercent: 0,
    DailyChange: calculateDailyChange(accountBalancesForAccountGroup),
    Accounts: accountBalancesForAccountGroup.map((accountBalance) => {
      return {
        AccountId: accountBalance.accountId,
        Nickname: accountBalance.accountNickname,
        DefaultName: accountBalance.accountDefaultName,
        DisplayName: accountBalance.accountNickname
          ? accountBalance.accountNickname
          : accountBalance.accountDefaultName,
        ManagementStyle: accountBalance.managementStyle,
        AccountType: accountBalance.accountType,
        MarketValue: accountBalance.marketValue,
        CashBalance: accountBalance.cashBalance,
        TotalBalance: accountBalance.totalBalance,
        UnrealizedGl: accountBalance.unrealizedGl,
        UnrealizedGlPercent: accountBalance.unrealizedGlPercent,
        DailyChange: accountBalance.dailyChange,
      } as AccountBalanceViewModel;
    }),
  };

  accountBalancesForAccountGroup.forEach((accountBalance) => {
    accountGroupBalance.MarketValue += accountBalance.marketValue;
    accountGroupBalance.CashBalance += accountBalance.cashBalance;
    accountGroupBalance.TotalBalance += accountBalance.totalBalance;
    accountGroupBalance.UnrealizedGl += accountBalance.unrealizedGl;
    accountGroupBalance.UnrealizedGlPercent +=
      accountBalance.unrealizedGlPercent ?? 0;
  });

  return accountGroupBalance;
};

export const projectAccountGroupBalances = (
  accountGroups: UserProfileAccountGroup[],
  accountBalancesData: AccountBalancesModel[]
): AccountGroupBalanceViewModel[] => {
  let remainAccountBalancesData = accountBalancesData;
  let accountBalancesForAccountGroup = [];

  let accountGroupBalanceViewModels: AccountGroupBalanceViewModel[] =
    accountGroups.map((accountGroup) => {
      [accountBalancesForAccountGroup, remainAccountBalancesData] = _.partition(
        remainAccountBalancesData,
        (accountBalance) =>
          accountGroup.accounts.some(
            (account) => account.financialAccountId === accountBalance.accountId
          )
      );

      return projectAccountGroupBalance(
        accountGroup,
        accountBalancesForAccountGroup
      );
    });

  if (remainAccountBalancesData.length > 0) {
    accountGroupBalanceViewModels.push(
      projectAccountGroupBalance(
        {
          id: '0',
          name: 'Ungrouped Accounts',
          accounts: [],
        },
        remainAccountBalancesData
      )
    );
  }

  return accountGroupBalanceViewModels.filter(
    (accountGroup) => accountGroup.Accounts.length > 0
  );
};

export interface AccountBalancesExcelData {
  'Account Group'?: string;
  'Account Name'?: string;
  'Management Style': string | null;
  'Account Type': string | null;
  'Market Value': number;
  'Cash & Cash Equivalents': number;
  'Total Balance': number;
  'Unrealized G/L': number;
  'Daily Change': number | null;
}

export const computeAccountBalancesByAccountGroupExcelData = (
  accountGroupData: AccountGroupBalanceViewModel[]
): AccountBalancesExcelData[] => {
  const excelData: AccountBalancesExcelData[] = [];

  accountGroupData.forEach((o) => {
    o.Accounts.forEach((o2) => {
      excelData.push({
        'Account Group': o.AccountGroupName,
        'Account Name': o2.Nickname || o2.DefaultName || '',
        'Management Style': o2.ManagementStyle,
        'Account Type': o2.AccountType,
        'Market Value': o2.MarketValue,
        'Cash & Cash Equivalents': o2.CashBalance,
        'Total Balance': o2.TotalBalance,
        'Unrealized G/L': o2.UnrealizedGl,
        'Daily Change': o2.DailyChange,
      });
    });
  });

  return excelData;
};

export const computeAccountBalancesAccountsOnlyExcelData = (
  data: AccountBalancesModel[]
): AccountBalancesExcelData[] => {
  const excelData: AccountBalancesExcelData[] = [];

  data.forEach((o) => {
    excelData.push({
      'Account Name': o.accountNickname || o.accountDefaultName || '',
      'Management Style': o.managementStyle,
      'Account Type': o.accountType,
      'Market Value': o.marketValue,
      'Cash & Cash Equivalents': o.cashBalance,
      'Total Balance': o.totalBalance,
      'Unrealized G/L': o.unrealizedGl,
      'Daily Change': o.dailyChange,
    });
  });

  return excelData;
};
