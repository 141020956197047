import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Suspense, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FilterItem } from 'src/components/table-filter/@types';
import ReportWrapper from '../ReportWrapper';

interface PortfolioChangeViewProps {
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
}

const PortfolioChangeView = ({ setAsOfDate }: PortfolioChangeViewProps) => {
  const date = '07/18/2023';

  if (date !== undefined && date !== null) {
    setAsOfDate(date);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h2">Coming soon</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

interface PortfolioChangeLoaderProps {
  data: any;
}
export const PortfolioChangeLoader = ({ data }: PortfolioChangeLoaderProps) => {
  const fakeTableFilterData: FilterItem[] = [
    {
      predicateIdentifier: '',
      displayValue: '',
      filterType: 'text',
    },
  ];
  const [filterValues, setFilterValues] =
    useState<FilterItem[]>(fakeTableFilterData);

  const [asOfDate, setAsOfDate] = useState<string | null>(null);

  return (
    <ReportWrapper title="Portfolio Change" landingPageQueryRef={data}>
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height={200} />}
      >
        <PortfolioChangeView
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setAsOfDate={setAsOfDate}
        />
      </Suspense>
    </ReportWrapper>
  );
};

export default PortfolioChangeLoader;
