import { FilterItem } from '@newedge/common';

export const TaxInsightsCategories = [
  'Unrealized Gain/Loss',
  'Realized Gain/Loss',
  'Total Gain/Loss',
] as const;

type TaxInsightsCategory = (typeof TaxInsightsCategories)[number];

export interface TaxInsightTableRowData {
  category: TaxInsightsCategory;
  startMonth: number;
  cashArray: Array<number>;
  details?: any[];
  eyebrowLinkText?: string;
  eyebrowLinkPath?: string;
  secondaryFilters?: FilterItem[];
}