import { useState, MouseEvent, useCallback, useMemo } from 'react';
import {
  Theme,
  List,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
  useMediaQuery,
  ListItemButton,
  Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { newEdgeNamedColors } from 'src/assets/theme';
import { NavigationRouteDefinition } from 'src/@types/global';
import ClientPortalLogo from '../branding/ClientPortalLogo';
import LogoutDialog from '../modals/LogoutDialog';
import MobileProfileDialog from 'src/views/profile/MobileProfileDialog';

interface NavigationDrawerProps {
  navDefinitions: NavigationRouteDefinition[];
  isDrawerOpen: boolean;
  toggleDrawerOpen: (toggleDrawer: boolean) => void;
  onNavigation: (location: string) => void;
}

export const NavigationDrawer = ({
  navDefinitions,
  isDrawerOpen,
  toggleDrawerOpen,
  onNavigation,
}: NavigationDrawerProps) => {
  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [nestedOpen, setNestedOpen] = useState('');
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const mouseEnterHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      toggleDrawerOpen(true);
      setNestedOpen('Reports');
    },
    [toggleDrawerOpen]
  );

  const mouseLeaveHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      toggleDrawerOpen(false);
      setNestedOpen('');
    },
    [toggleDrawerOpen]
  );

  const groupClickHandler = useCallback(
    (groupTitle: string) => {
      if (nestedOpen !== '') {
        setNestedOpen('');
      } else {
        setNestedOpen(groupTitle);
      }
    },
    [nestedOpen]
  );

  const wrapNested = useCallback(
    (component: any, nested?: string) => {
      if (!!nested) {
        return (
          <Collapse
            key={`wrap_nested_collapse_1_${component?.key}`}
            in={!!(nestedOpen && nestedOpen === nested)}
            timeout="auto"
            unmountOnExit
          >
            {component}
          </Collapse>
        );
      } else {
        return component;
      }
    },
    [nestedOpen]
  );

  const handleMobileProfileClick = useCallback(() => {
    setProfileModalOpen(true);
    toggleDrawerOpen(false);
  }, [toggleDrawerOpen]);

  const getClickHandler = useCallback(
    (navItem: NavigationRouteDefinition) => () => {
      if (navItem.groupTitle) {
        groupClickHandler(navItem.title);
        return;
      }
      if (isMobileOrTablet) {
        toggleDrawerOpen(false);
        setNestedOpen('');
        if (navItem.path === 'profile') {
          handleMobileProfileClick();
          return;
        }
      }
      onNavigation(location.pathname);
      navigate(`/my/${navItem.path}`);
    },
    [
      groupClickHandler,
      handleMobileProfileClick,
      isMobileOrTablet,
      navigate,
      toggleDrawerOpen,
    ]
  );

  const renderDrawerList = useCallback(() => {
    return (
      <List
        sx={{
          position: 'absolute',
          opacity: 1,
          transition: 'transform 0.5s ease',
          width: '100%',
        }}
      >
        {navDefinitions.map((navItem) => {
          const { path, icon, title, groupTitle, nested } = navItem;
          const locationParts = location.pathname.split('/');
          const nestedPaths: string[] = [];
          if (groupTitle) {
            navDefinitions.forEach((o) => {
              if (o.nested === title && o.path) {
                nestedPaths.push(o.path);
              }
            });
          }

          return wrapNested(
            <ListItemButton
              selected={
                (path && location.pathname.endsWith(path)) ||
                (groupTitle &&
                  !isDrawerOpen &&
                  nestedPaths.includes(locationParts[locationParts.length - 1]))
              }
              sx={{
                mb: 0.5,
                cursor: 'pointer',
                '& .MuiListItemText-root': {
                  color: 'gray',
                  textTransform: 'uppercase',
                  fontSize: '1.4rem',
                  lineHeight: '1.6rem',
                },
                '& .MuiListItemIcon-root': {
                  minWidth: '32px',
                  marginRight: '15px',
                  fontSize: '24px',
                  color: 'gray',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0)',
                  '& .MuiListItemText-root': {
                    color: 'white',
                    marginLeft: '10px',
                  },
                  '& .MuiListItemIcon-root': {
                    color: 'white',
                  },
                },
                paddingLeft: nested ? 4 : undefined,
                '&.Mui-selected': {
                  '& .MuiListItemText-root': {
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.4rem',
                    lineHeight: '1.6rem',
                  },
                  '& .MuiListItemIcon-root': {
                    background:
                      'transparent linear-gradient(155deg, #0A42C6 0%, #1C60FF 100%) 0% 0% no-repeat padding-box',
                    padding: '6px',
                    borderRadius: '20%',
                    minWidth: '33px',
                    margin: '-6px',
                    marginRight: '15px',
                    color: 'white',
                    fontSize: '24px',
                  },
                  backgroundColor: 'rgba(0,0,0,0) !important',
                  color: newEdgeNamedColors.white,
                },
              }}
              key={`${path}_${icon?.key}_${title}_${groupTitle}_${nested}`}
              onClick={getClickHandler(navItem)}
            >
              {icon && (
                <ListItemIcon
                  sx={{ color: newEdgeNamedColors.white, fontSize: '2.4rem' }}
                >
                  {icon}
                </ListItemIcon>
              )}
              {
                <ListItemText
                  primary={title}
                  disableTypography
                  sx={{ whiteSpace: 'nowrap' }}
                />
              }
            </ListItemButton>,
            nested
          );
        })}
        {isMobileOrTablet && (
          <div>
            <LogoutDialog />
          </div>
        )}
      </List>
    );
  }, [
    getClickHandler,
    isDrawerOpen,
    isMobileOrTablet,
    location.pathname,
    navDefinitions,
    wrapNested,
  ]);

  const drawerChildren = useMemo(
    () => (
      <div
        key={`navigation_drawer_div_1`}
        onMouseOver={
          !isMobileOrTablet ? (e) => mouseEnterHandler(e) : undefined
        }
        onMouseEnter={
          !isMobileOrTablet ? (e) => mouseEnterHandler(e) : undefined
        }
        onMouseLeave={
          !isMobileOrTablet ? (e) => mouseLeaveHandler(e) : undefined
        }
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          key={`navigation_drawer_div_2`}
          sx={{
            width: '100%',
            py: 1,
            px: 0.5,
            mb: 13.375,
          }}
        >
          <ClientPortalLogo />
        </Box>
        {renderDrawerList()}
        <MobileProfileDialog
          isModalOpen={profileModalOpen}
          toggleModalOpen={setProfileModalOpen}
          toggleDrawerOpen={toggleDrawerOpen}
        />
      </div>
    ),
    [
      isMobileOrTablet,
      mouseEnterHandler,
      mouseLeaveHandler,
      profileModalOpen,
      renderDrawerList,
      toggleDrawerOpen,
    ]
  );

  return (
    <nav>
      <Drawer
        open={isDrawerOpen}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: theme.clientPortalExtensions.drawerWidth,
            position: 'absolute',
            flexShrink: 0,
          },
          '& .MuiDrawer-paper': !isDrawerOpen
            ? {
                width: '65px',
                padding: theme.spacing(1),
                overflow: 'hidden',
                transition: 'all 0.5s ease',
                color: newEdgeNamedColors.white,
              }
            : {
                width: theme.clientPortalExtensions.drawerWidth,
                padding: theme.spacing(1),
                overflow: 'hidden',
                transition: 'all 0.5s ease',
                color: newEdgeNamedColors.white,
                [theme.breakpoints.down('sm')]: { overflow: 'scroll' },
              },
          '& .MuiDrawer-paperAnchorDockedLeft': { border: 'none' },
        })}
        variant={isMobileOrTablet ? 'temporary' : 'permanent'}
        ModalProps={{ keepMounted: true }}
      >
        {drawerChildren}
      </Drawer>
    </nav>
  );
};

export default NavigationDrawer;
